import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import Button from "../../components/button/button";
import HeaderLight from "../../components/header/headerLight/headerLight";

import { submit } from "../../utils/submit";
import ReCAPTCHA from "react-google-recaptcha"; 

import "./style.scss";
import InputFile from "../../shared/ui/inputFile";
import InputTelephone from "../../shared/ui/inputTelephone";
import InputMail from "../../shared/ui/inputMail";
import Select from "../../shared/ui/select";
import FormError from "../../components/formError";

import charteMediation from "../../../static/pdfs/CHARTE_DU_MEDIATEUR_2021.pdf";
import ChampsObligatoiresMention from "../../components/champsObligatoiresMention";

const Reclamations = ({ location }) => {

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [infoForm, setInfoForm] = useState({"code":"reclamations"});
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, type) => {
    setInfoForm((prevInfo) => ({
      ...prevInfo,
      [e.target.name]:
        type === "file" ? e.target.files[0] : e.target.value.trim(),
    }));

    if(e?.target?.name === 'societaire-auxiliaire' && e?.target?.value === 'non') {
      setInfoForm((prevInfo) => ({
        ...prevInfo,
        'num-societaire': undefined
      }));
    }

    if(e?.target?.name === 'type-reclamation' && e?.target?.value !== "sinistre") {
      setInfoForm((prevInfo) => ({
        ...prevInfo,
        'num-dossier': undefined,
      }));
    }
  };

  const handleError = () => {
    setFormError(true);
    document.getElementById("form-error")?.scrollIntoView();
  }

  return (
    <>
      <Helmet htmlAttributes={{
        class: "no-scrolling"
      }}>
        <body className="reclamations" />
      </Helmet>
      <Layout>
        <SEO title="Réclamations" />
        <HeaderLight
          title={
            <>
              Un mécontentement&nbsp;? Une insatisfaction&nbsp;?{" "}
              <strong>Faites-nous part de votre réclamation&nbsp;!</strong>
            </>
          }
          location={location}
          crumbLabel="Réclamation"
        />
        <section className="reclamations__section reclamations__text">
          <p>
            Nous vous invitons à contacter tout d'abord votre interlocuteur
            habituel afin de lui faire part de votre attente. Si vous souhaitez
            malgré tout nous contacter à propos d'une insatisfaction, vous
            pouvez le faire :
          </p>
          <br />
          <ul>
            <li>Via notre formulaire ci-dessous</li>
            <li>En écrivant à l'adresse email : <a href={"mailto:reclamations@auxiliaire.fr"}>reclamations@auxiliaire.fr</a></li>
            <li>
              Par courrier postal à l'adresse suivante : L'Auxiliaire - Service
              Relations Clients - 20 rue Garibaldi - BP 6402 - 69413
              Lyon cedex 06
            </li>
          </ul>
          <br />
          <p>
            Nous nous engageons à satisfaire au mieux votre demande, à vous
            accuser réception sous 3 jours ouvrés et à vous fournir une réponse
            sous 10 jours ouvrés.
          </p>
          <p>
            Si toutefois nous ne trouvions pas de compromis, vous pouvez
            adresser votre demande par courrier à : La Médiation de l'Assurance,
            TSA 50110, 75441 Paris Cedex 09 ou directement en ligne{" "}
            <a className="underline" href="">ici</a>
          </p>

          <Button
            className="u-mt-big btn-secondary"
            text="Télécharger la charte de la médiation"
            to={charteMediation}
            download={true}
          />
        </section>
        <form
          action=""
          className="form reclamations__section"
          onSubmit={(e) => {
            e.preventDefault();
            if(!isLoading && isCaptchaValid) {
              setIsLoading(true);
              submit(infoForm).then((response) => {
                if(response?.ok) {
                  navigate("/merci-pour-votre-demande", {
                    state: {
                      about: "reclamation"
                    }
                  });
                } else {
                  handleError();
                }
                setIsLoading(false);
              }, () => {
                handleError();
                setIsLoading(false);
              });
            }
          }}
        >
          {formError && <FormError />}
          <div className="form__radio">
            <p className="form__radio-question">Votre civilité *</p>
            <div className="form__radio-container">
              <div>
                <input
                  type="radio"
                  name="civilite"
                  id="madame"
                  value="Madame"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="madame">Madame</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="civilite"
                  id="monsieur"
                  value="Monsieur"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="monsieur">Monsieur</label>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="nom">votre</label>
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="nom *"
              onChange={handleChange}
              required
            />
            <span>,</span>
            <input
              type="text"
              name="prenom"
              id="prenom"
              placeholder="prénom *"
              onChange={handleChange}
              required
            />
            
          </div>
          <div>
            <label htmlFor="email">votre</label>
            <InputMail
              inputName="email"
              inputId="email"
              inputPlaceholder="adresse mail *"
              inputRequired={true}
              inputClassNames="longer"
              inputHandleChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="tel">votre</label>
            <InputTelephone
                inputName="tel"
                inputId="tel"
                inputPlaceholder="numéro de téléphone *"
                inputHandleChange={handleChange}
                inputRequired={true}
                inputClassNames="longer"
            />
          </div>
          <div>
            <label htmlFor="adresse">votre</label>
            <input
              type="text"
              name="adresse"
              id="adresse"
              placeholder="adresse *"
              onChange={handleChange}
              required
              className="longer"
            />
          </div>
          <div>
            <p>ainsi que votre</p>
            <input
              type="number"
              name="code-postal"
              id="code-postal"
              max={99999}
              onInput={(e) => e.target.value = e.target.value.slice(0, 5)}
              placeholder="code postal *"
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="ville"
              id="ville"
              placeholder="ville *"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form__radio u-mt-big">
            <p className="form__radio-question">
              Êtes-vous sociétaire de L’Auxiliaire&nbsp;? *
            </p>
            <div className="form__radio-container">
              <div>
                <input
                  type="radio"
                  name="societaire-auxiliaire"
                  id="societaire-auxiliaire-yes"
                  value="oui"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="societaire-auxiliaire-yes">Oui</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="societaire-auxiliaire"
                  id="societaire-auxiliaire-no"
                  onChange={handleChange}
                  value="non"
                  required
                />
                <label htmlFor="societaire-auxiliaire-no">Non</label>
              </div>
            </div>
          </div>
          {infoForm['societaire-auxiliaire'] === 'oui' &&
            <div>
              <span>Quel est votre</span>
              <input
                  type="number"
                  name="num-societaire"
                  id="num-societaire"
                  placeholder="numéro de sociétaire *"
                  onChange={handleChange}
                  required
              />
              <span>?</span>
            </div>
          }
          <div>
            <span>Quel est le</span>
            <input
                type="number"
                name="num-contrat"
                id="num-contrat"
                placeholder="numéro de contrat *"
                onChange={handleChange}
                required
            />
            <span>concerné&nbsp;?</span>
          </div>
          <div className="u-mt-big">
            <span>votre réclamation concerne</span>
            <Select
                inputId="type-reclamation"
                inputName="type-reclamation"
                inputRequired={true}
                inputHandleChange={handleChange}
                inputPlaceholder="sélectionner un sujet *"
            >
              <option value="sinistre">Un sinistre en cours</option>
              <option value="gestion-admin-contrat">La gestion administrative de votre contrat</option>
              <option value="cotisation">Le montant/paiement de votre cotisation</option>
              <option value="souscription-resiliation-contrat">La souscription/résilitation de votre contrat</option>
              <option value="autre">Autre (précisez dans la zone de texte)</option>
            </Select>
          </div>
          {infoForm['type-reclamation'] === "sinistre" &&
              <div>
                <span>Quel est le</span>
                <input
                    type="number"
                    name="num-dossier"
                    id="num-dossier"
                    placeholder="numéro du dossier *"
                    onChange={handleChange}
                    required
                />
                <span>du sinistre&nbsp;?</span>
              </div>
          }
          <div className="u-mt-big">
            <label htmlFor="texte-libre">Quelle est votre attente&nbsp;?</label>
            <textarea
              name="texte-libre"
              id="texte-libre"
              cols="30"
              rows="10"
              onChange={handleChange}
              placeholder="Texte libre"
            ></textarea>
          </div>
          <div>
            <label htmlFor="fichier">Envoyer un fichier si besoin...</label>
            <InputFile
                inputId="fichier"
                inputName="fichier"
                handleChange={(e) => handleChange(e, "file")}
            />
          </div>
          <ChampsObligatoiresMention showFinaliteTraitementDonnees={true} />
          <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={(val)=>{setIsCaptchaValid(!!val)}}
          />
          <div className="u-mt-big">
            <Button text="Envoyer" type="submit" className="reclamations__btn" isDisabled={!isCaptchaValid} isLoading={isLoading} />
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Reclamations;
