import React, {useState} from "react";

export default function Select({
                                   inputId,
                                   inputName,
                                   inputValue,
                                   inputRequired = false,
                                   inputClassNames = "",
                                   inputHandleChange,
                                   inputPlaceholder,
                                   children
                               }) {
    const [selectColor, setSelectColor] = useState('#979797');

    const changeColor = (e) => {
        if(e?.target?.selectedIndex > 0) {
            setSelectColor('#ec6608');
        } else {
            setSelectColor('#979797');
        }
    }


    return (
        <select
            id={inputId}
            name={inputName}
            value={inputValue}
            required={inputRequired}
            className={inputClassNames}
            onChange={inputHandleChange}
            onInput={changeColor}
            defaultValue=""
            style={{
                color: selectColor,
                backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='${selectColor?.replace('#', '%23')}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`
            }}
        >
            <option value="" hidden disabled>{inputPlaceholder}</option>
            {children}
        </select>
    )
}
